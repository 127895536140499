<template>
	<div class="login">
		<div class="LoginForm">
			<img src="../assets/lift-mart-logo.webp">
			<img src="../assets/login-page-image.webp"/>
			<span style="font-size: 22px">Sign In</span>
			<span style="font-size: 12px">Let’s get you started</span>
			<n-button style="width: 250px" type="info" color="#2729A3" @click="useEmailOrPhone()">Use Email or Phone Number</n-button>
		</div>
	</div>
</template>

<style>
.login {
	height: 100%;
	display: grid;
	grid-template-columns: 1fr 350px 1fr;
	grid-template-rows: 0.3fr 5.9fr 0.3fr; 
	gap: 0px 0px;
	grid-template-areas: 
		". . ."
		". LoginForm ."
		". . .";
	align-items: center;
}
.LoginForm {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 32px 350px 22px 50px 50px 16px 50px 50px;
	gap: 16px 0px;
	grid-template-areas: 
		"."
		"."
		"."
		"."
		"."
		"."
		"."
		".";
	align-content: center;
	justify-items: center;
	grid-area: LoginForm;
}
.or {
	width: 250px;
	display: grid;
	grid-template-columns: 1fr 16px 1fr;
	grid-template-rows: 1fr;
	gap: 0px 16px;
	grid-template-areas: 
		". . .";
	align-items: center;
}
</style>

<script setup>
import { useRouter } from 'vue-router'

const router = useRouter()

function useEmailOrPhone() {
	router.push({ name: 'auth' })
}
</script>