import { createRouter, createWebHashHistory } from 'vue-router'
import { Auth } from 'aws-amplify'
import DesignView from '../views/DesignView.vue'
import DeleteAccountView from '../views/DeleteAccountView.vue'
import LoginView from '../views/LoginView.vue'
import AuthView from '../views/AuthView.vue'

const routes = [
	{
		path: '/',
		name: 'login',
		component: LoginView
	},
	{
		path: '/auth',
		name: 'auth',
		component: AuthView
	},
	{
		path: '/deleteaccount',
		name: 'deleteaccount',
		component: DeleteAccountView
	},
	{
		path: '/design',
		name: 'design',
		component: DesignView
	}
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
		try {
			await Auth.currentAuthenticatedUser();
			next();
		} catch (error) {
			console.error(error);
			next('/');
		}
	} else {
		next();
	}
})

export default router
