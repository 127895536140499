<template>
	<div class="auth">
		<div class="authform">
			<span>Auth</span>
			<div class="tabs">
				<button id="tab-email" class="tab" :disabled="step === 1" @click="openTab('email')">
					Email
				</button>
				<button id="tab-phone" class="tab" :disabled="step === 1" @click="openTab('phone')">
					Phone
				</button>
				<button id="tab-verify" class="tab" @click="openTab('verify')" style="display: none;">
					Verify
				</button>
			</div>
			<div id="email" class="tab-content">
				<n-input v-model:value="email" type="email" placeholder="Email address" />
				<span style="font-size: 9px;">You will receive a 6 digit verification code on this email</span>
				<n-input v-model:value="password_input" type="password" placeholder="Password" />
				<span style="font-size: 9px;">
					By clicking ‘Next’, I understand and agree to Lift Mart's Terms & Conditions and Legal Notice for creating an Account and I authorize Lift Mart to contact me for account management purposes via the contact information I provide. I understand calls or texts may use automatic or computer-assisted dialing or pre-recorded messages. Normal message and data rates may apply.
				</span>
			</div>
			<div id="phone" class="tab-content">
				<n-input-group>
					<n-auto-complete v-model:value="countrycode" :style="{ width: '33%' }" :input-props="{ autocomplete: 'disabled' }" :options="options" placeholder="-- +123" clearable/>
					<n-input-number v-model:value="phone_number" :style="{ width: '67%' }" type="number" placeholder="123456789" :show-button="false"/>
				</n-input-group>
				<span style="font-size: 9px;">You will receive a 6 digit verification code on this number</span>
				<n-input v-model:value="password_input" type="password" placeholder="Password" />
				<span style="font-size: 9px;">
					By clicking ‘Next’, I understand and agree to Lift Mart's Terms & Conditions and Legal Notice for creating an Account and I authorize Lift Mart to contact me for account management purposes via the contact information I provide. I understand calls or texts may use automatic or computer-assisted dialing or pre-recorded messages. Normal message and data rates may apply.
				</span>
			</div>
			<div id="verify" class="tab-content">
				<div class="verificationcode">
					<n-input-number v-model:value="verificationcode[0]" ref="codeA" placeholder="" :show-button="false"/>
					<n-input-number v-model:value="verificationcode[1]" ref="codeB" placeholder="" :show-button="false"/>
					<n-input-number v-model:value="verificationcode[2]" ref="codeC" placeholder="" :show-button="false"/>
					<n-input-number v-model:value="verificationcode[3]" ref="codeD" placeholder="" :show-button="false"/>
					<n-input-number v-model:value="verificationcode[4]" ref="codeE" placeholder="" :show-button="false"/>
					<n-input-number v-model:value="verificationcode[5]" ref="codeF" placeholder="" :show-button="false"/>
				</div>
				<span style="font-size: 9px;">Please type in the 6 digit verification code </span>
				<span style="font-size: 9px;">
					By clicking ‘Next’, I understand and agree to Lift Mart's Terms & Conditions and Legal Notice for creating an Account and I authorize Lift Mart to contact me for account management purposes via the contact information I provide. I understand calls or texts may use automatic or computer-assisted dialing or pre-recorded messages. Normal message and data rates may apply.
				</span>
			</div>
			<div>
				<n-button :disabled="loading" type="info" color="#2729A3" block strong @click="next()">
					Next
				</n-button>
			</div>
		</div>
	</div>
</template>

<style>
.auth {
	height: 100%;
	display: grid;
	grid-template-columns: 1fr 350px 1fr;
	grid-template-rows: 0.3fr 5.9fr 0.3fr;
	gap: 0px 0px;
	grid-template-areas: 
		". . ."
		". authform ."
		". . .";
}
.authform {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 0.5fr 0.5fr 3.8fr 0.5fr;
	gap: 16px 0px;
	grid-template-areas: 
		"."
		"tabs"
		"."
		".";
	grid-area: authform;
}
.tabs {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;
	gap: 0px 0px;
	grid-template-areas: 
		". .";
	justify-items: center;
	grid-area: tabs;
}
.tab {
	width: 100%;
	padding: 10px 20px;
	border: none;
	border-bottom: 2px solid grey;
	background: none;
	text-decoration: none;
	color: #333;
}
.tab:hover {
	color: #2729A3;
}
.tab.active {
	border-bottom: 2px solid #2729A3;
	color: #2729A3;
}
.tab-content {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr 1fr 1fr;
	gap: 0px 0px;
	grid-template-areas:
		"."
		"."
		".";
	align-self: center; 
	justify-content: center;
	align-content: center;
	justify-items: center;
	align-items: center;
}
.verificationcode {
  display: grid; 
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; 
  grid-template-rows: 1fr; 
  gap: 0px 0px; 
  grid-template-areas: 
	". . . . . ."; 
  justify-content: center; 
  align-content: center; 
  align-items: center; 
}
</style>

<script setup>
import { onMounted, ref, reactive } from 'vue'
import { signUp, confirmSignUp, signIn } from 'aws-amplify/auth';
import { useRouter } from 'vue-router'
import { useMessage } from "naive-ui";

const loading = ref(false);
const message = useMessage()
const router = useRouter()

const options = ref(["AE +971","EG +20","CN +86","KZ +7"])
const currentTab = ref("email")
const tabs = ref([])
const tabLinks = ref([])

const email = ref(null);
const password_input = ref(null);
const countrycode = ref(null);
const phone_number = ref(null);

const verificationcode = reactive([null,null,null,null,null,null]);

const step = ref(0);

// const completephonenumber = computed(() => {
//   return countrycode.value.replace(/[^0-9]/g, '') + phone_number.value;
// })

function openTab(tabName) {
	if(tabName === "phone") {
		message.info("This option is currently unavailable");
	} else {
		var i;
		for (i = 0; i < tabs.value.length; i++) tabs.value[i].style.display = "none";
		for (i = 0; i < tabLinks.value.length; i++) tabLinks.value[i].classList.remove("active");
		document.getElementById(tabName).style.display = "grid";
		document.getElementById("tab-"+tabName).classList.add("active");
		currentTab.value = tabName;
	}
}

function next() {
	if(step.value === 0) liftMartSignUp();
	if(step.value === 1) liftMartVerify();
}

async function liftMartSignUp() {
	loading.value = true;
	var username = email.value;
	var user_email  = email.value;
	var password = password_input.value;
	var attributes = attributes = {
		email: user_email
	};
	if(currentTab.value === "phone" && countrycode.value) {
		var user_phone_number = "+" + countrycode.value.replace(/[^0-9]/g, '') + phone_number.value;
		username = user_phone_number;
		attributes = {
			phone_number: user_phone_number
		}
	}
	var params = {
		username: username,
		password: password,
		options: {
			attributes: attributes,
			autoSignIn: {
				enabled: true,
			}
		}
	};
	console.log("params",params)
	try {
		const { user } = await signUp({
			username,
			password,
			options: {
				attributes: attributes,
				autoSignIn: {
					enabled: true,
				}
			}
		});
		console.log(user);
		liftMartSignIn();
	} catch (error) {
		if (error.name === "UsernameExistsException") {
			console.log("User already exists.");
			liftMartSignIn();
		} else {
			console.log("Error signing up:", error);
			loading.value = false;
		}
	}
}

async function liftMartVerify() {
	try {
		var username = email.value
		var code = verificationcode.join("")
		await confirmSignUp({
			username,
			code
		});
		liftMartSignIn();
	} catch (error) {
		console.log('error confirming sign up', error);
		loading.value = false;
	}
}

async function liftMartSignIn() {
	try {
		var username = email.value
		var password = password_input.value
		const user = await signIn({
			username,
			password
		});
		console.log("User signed in:", user);
		router.push({ name: 'deleteaccount' })
		loading.value = true;
	} catch (error) {
		if (error.code === "UserNotConfirmedException") {
			console.log("User is not confirmed.");
			openTab("verify");
			step.value = 1;
		} else {
		console.log("Error signing in:", error);
	}
		loading.value = false;
	}
}

onMounted(() => {
	tabs.value = document.getElementsByClassName("tab-content");
	tabLinks.value = document.getElementsByClassName("tab");
	document.getElementById(currentTab.value).style.display = "grid";
	document.getElementById("phone").style.display = "none";
	document.getElementById("verify").style.display = "none";
	document.getElementsByClassName("tab")[0].classList.add("active");
})
</script>
