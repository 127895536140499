<template>
	<div class="deleteaccount">
		<div class="options">
			<n-button style="width: 250px" type="info" color="#2729A3" ghost @click="makeNewDesign()">Make New Design</n-button>
			<n-popconfirm
				@positive-click="deleteAccount">
				<template #trigger>
					<n-button style="width: 250px" type="info" color="#a32727" ghost>Delete Account</n-button>
				</template>
				Are you sure you want to delete your account?
			</n-popconfirm>
		</div>
	</div>
</template>

<style>
.deleteaccount {
	height: 100%;
	display: grid; 
	grid-template-columns: 1fr 512px 1fr; 
	grid-template-rows: 1fr 128px 1fr; 
	gap: 0px 0px; 
	grid-template-areas: 
	". . ."
	". options ."
	". . ."; 
}
.options {
	display: grid; 
	grid-template-columns: 1fr 1fr; 
	grid-template-rows: 1fr; 
	gap: 0px 18px; 
	grid-template-areas: 
	". ."; 
	grid-area: options; 
}
</style>

<script setup>
import { useRouter } from 'vue-router'
import { Auth } from 'aws-amplify'

const router = useRouter()

function makeNewDesign() {
	router.push({ name: 'design' })
}

async function deleteAccount() {
	try {
		await Auth.deleteUser();
		router.push({ name: 'login' })
	} catch (error) {
		console.log(error);
	}
}
</script>
