<template>
	<div class="home">
		<n-space id="controls">
			<n-button-group>
				<n-button circle @click="resetZoom"><template #icon><n-icon><zoom-cancel-icon /></n-icon></template></n-button>
				<n-button circle @click="exportDesign"><template #icon><n-icon><file-export-icon /></n-icon></template></n-button>
			</n-button-group>
		</n-space>
		<n-progress id="unityLoadingBar" v-if="percentage < 100" class="center" type="circle" :percentage="percentage" processing/>
		<VueUnity id="unity" :unity="unityContext"></VueUnity>
		<div class="centered-container">
			<div v-if="data[0].options.length === 0" class="fixed-row">
				<n-skeleton height="80px" width="100%"/>
			</div>
			<div v-else class="fixed-row">
				<n-badge  v-for="(option, i) in data[0].options" :key="i" :value="1" :show="option.isSelected" dot class="fixed-item no-select" >
					<n-button style="height: 80px;" @click="onClick(0, i)">{{ option.displayName }}</n-button>
				</n-badge>
			</div>
			<div v-if="data[1].options.length === 0" class="scrollable-grid">
				<n-skeleton height="200px" width="100%"/>
			</div>
			<div v-else class="scrollable-grid">
				<div  class="grid-container">
					<n-badge v-for="(option, i) in data[1].options" :key="i" :value="1" :show="option.isSelected" dot  class="item no-select" >
						<n-button style="height: 80px;" @click="onClick(1, i)">{{ option.displayName }}</n-button>
					</n-badge>
				</div>
			</div>
		</div>
		<n-modal v-model:show="showModal" :mask-closable="false" :close-on-esc="false" transform-origin="center">
			<n-card style="width: 600px" title="Project and Cabin Details" :bordered="false" size="huge" role="dialog" aria-modal="true">
				<n-space vertical>
					<p>Project Name</p>
					<n-input v-model:value="projectName" type="text" placeholder="Project Name" />
					<p>Brand</p>
					<n-select v-model:value="brand" :options="options.brands" />
					<p>Type</p>
					<n-select :disabled="brand === null" v-model:value="type" :options="availableTypes" />
					<p>Size</p>
					<n-select :disabled="type == null" v-model:value="size" :options="availableSizes" />
				</n-space>
				<template #footer>
					<n-button :disabled="brand === null && size === null && type === null" type="info" color="#2729A3" block strong @click="loadDesign()">
						Apply
					</n-button>
				</template>
			</n-card>
		</n-modal>
	</div>
</template>

<style>
body, html {
	width: 100%;
	height: 100%;
	margin: 0;
	font-family: Arial, sans-serif;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	background-color: #f5f5f5;
	overflow: hidden;
}

.home {
	position: relative;
	width: 100%;
	height: 100%;
}

#unityLoadingBar {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
}

#controls {
	position: absolute;
	top: 32px;
	right: 32px;
	z-index: 1;
}

.centered-container {
	position: absolute;
	width: 384px;
	max-height: 384px;
	left: 50%;
	bottom: 0px;
	border: 1px solid #ccc;
	background-color: #fff;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	border-radius: 24px 24px 0px 0px;
	transform: translate(-50%, 100%);
	transition: transform 0.3s ease-out;
	z-index: 2;
}

.fixed-row {
	display: flex;
	justify-content: space-between;
	padding: 24px;
	border-bottom: 1px solid #ccc;
}

.fixed-item-loading {
	width: 80px;
	height: 80px;
	display: flex;
}

.fixed-item {
	width: 80px;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.scrollable-grid {
	height: 200px;
	overflow-y: auto;
	padding: 24px;
}

.grid-container {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 8px;
}

.item {
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.no-select {
	-webkit-user-select: none;  /* Chrome, Safari, Opera */
	-moz-user-select: none;     /* Firefox */
	-ms-user-select: none;      /* IE 10+ */
	user-select: none;          /* Standard syntax */
}
</style>

<script setup>
import { onMounted, ref, reactive, computed } from 'vue'
import UnityWebgl from 'unity-webgl'
import VueUnity from 'unity-webgl/vue'
import { ZoomCancel as ZoomCancelIcon } from "@vicons/tabler"
import { FileExport as FileExportIcon } from "@vicons/tabler"

const showModal = ref(false)
const percentage = ref(0)
const unityContext = new UnityWebgl({
  "dataUrl": "https://cdn.holofair.io/build/2022.3.27f1/graphicstest/1.2.8/Build/WebGL.data.unityweb",
  "frameworkUrl": "https://cdn.holofair.io/build/2022.3.27f1/graphicstest/1.2.8/Build/WebGL.framework.js.unityweb",
  "loaderUrl": "https://cdn.holofair.io/build/2022.3.27f1/graphicstest/1.2.8/Build/WebGL.loader.js",
  "codeUrl": "https://cdn.holofair.io/build/2022.3.27f1/graphicstest/1.2.8/Build/WebGL.wasm.unityweb",
  "streamingAssetsUrl": "https://cdn.holofair.io/build/2022.3.27f1/graphicstest/1.2.8/StreamingAssets",
  "companyName": "Amrullah Mishelov",
  "productName": "ElevatorDesigner",
  "productVersion": "1.2.8"
})

unityContext.on('beforeMount', (ctx) => {
		console.log('beforeMount', ctx)
	}).on('mounted', (ctx) => {
		console.log('mounted', ctx)
	}).on('beforeUnmount', (ctx) => {
		console.log('beforeUnmount', ctx)
	}).on('progress', (progress) => {
		percentage.value = parseFloat((progress*100).toFixed(1));
	}).on('device', () => alert('click device ......'))

const isDragging = ref(false)
const initialY = ref(0)
const currentY = ref(0)

const projectName = ref(null)
const brand = ref(null)
const type = ref(null)
const size = ref(null)
const location = ref(null)

const container = ref(null)

const defaultLift = ref({
	"size": "0.9x1.0",
	"brand": "liftmart",
	"elevator_type": "passenger_elevator",
	"entrance_type": "single_entrance"
});

const options = ref({
	brands: [
		{
			label: "Lift Mart",
			value: 0,
			unity: "liftmart"
		},
		{
			label: "ultra Fuji",
			value: 1,
			unity: "ultrafuji"
		}
	],
	types: [
		{
			label: "Passenger Elevator",
			value: 0,
			brands: [0,1]
		},
		{
			label: "Panoramic Elevator",
			value: 1,
			brands: [0,1]
		},
		{
			label: "Service Elevator",
			value: 2,
			brands: [1]
		},
		{
			label: "Car Elevator",
			value: 3,
			brands: [1]
		}
	],
	sizes: [
		{
			label: "900mm x 1000mm x 2300mm",
			value: 0,
			link: "https://holofair-mena.s3.me-south-1.amazonaws.com/designs/0.9x1.0.json",
			brands: [0,1],
			types: [0,1,2,3]
		},
		{
			label: "1000mm x 1200mm x 2300mm",
			value: 1,
			link: "https://holofair-mena.s3.me-south-1.amazonaws.com/designs/1.0x1.2.json",
			brands: [0,1],
			types: [0,1,2,3]
		},
		{
			label: "1100mm x 1400mm x 2300mm",
			value: 2,
			link: "https://holofair-mena.s3.me-south-1.amazonaws.com/designs/1.1x1.4.json",
			brands: [0,1],
			types: [0,1,2,3]
		},
		{
			label: "1300mm x 1500mm x 2300mm",
			value: 3,
			link: "https://holofair-mena.s3.me-south-1.amazonaws.com/designs/1.3x1.5.json",
			brands: [0,1],
			types: [0,1,2,3]
		},
		{
			label: "1400mm x 1600mm x 2300mm",
			value: 4,
			link: "https://holofair-mena.s3.me-south-1.amazonaws.com/designs/1.4x1.6.json",
			brands: [1],
			types: [0,1,2,3]
		},
		{
			label: "1500mm x 1900mm x 2300mm",
			value: 5,
			link: "https://holofair-mena.s3.me-south-1.amazonaws.com/designs/1.5x1.9.json",
			brands: [1],
			types: [0,1,2,3]
		},
		{
			label: "1600mm x 2100mm x 2300mm",
			value: 6,
			link: "https://holofair-mena.s3.me-south-1.amazonaws.com/designs/1.6x2.1.json",
			brands: [1],
			types: [3]
		},
		{
			label: "1800mm x 2300mm x 2300mm",
			value: 7,
			link: "https://holofair-mena.s3.me-south-1.amazonaws.com/designs/1.8x2.3.json",
			brands: [1],
			types: [3]
		}
	]
})

const availableTypes = computed(() => {
  return options.value.types.filter(item => item.brands.includes(brand.value));
})

const availableSizes = computed(() => {
  return options.value.sizes.filter(item => item.types.includes(type.value) && item.brands.includes(brand.value));
})

const data = reactive([
	{
		"viewid": 0,
		"options": []
	},
	{
		"viewid": 1,
		"options": []
	}
]);

function resetZoom() {
	unityContext.send("CameraController","ResetView");
}

function onClick(viewid, i) {
	for (var j = data[viewid].length - 1; j >= 0; j--) data[viewid][j].isSelected = j === i;
	unityContext.send("CompositeViews","Select", viewid+","+i);
}

function exportDesign() {
	unityContext.send("SubjectsFactory","StartExport");
}

function handleMessage(event) {
	console.log("Received event from Unity:", event);
	if("state" in event) {
		if(event.state === "show") {
			var message = event.data;
			if(message.viewid >= data.length) return;
			data[message.viewid].options = message.options;
			container.value.style.transition = 'transform 0.3s ease-out';
			if(message.viewid === 0) container.value.style.transform = 'translate(-50%, 65%)'; 
			else if(message.viewid === 1) container.value.style.transform = 'translate(-50%, 0)';
		} else if (event.state === "loaded" && event.scene === "MainScene") {
			// showModal.value = true;
			unityContext.send("SubjectsFactory","Load",JSON.stringify(defaultLift.value));
			unityContext.send("SubjectsFactory","ClearVariables");
		} else if (event.state === "export") {
			event.json.projectname = {
				displayName: projectName.value,
				displayOnPDF: projectName.value,
				val: projectName.value
			};
			event.json.elevatorbrand = {
				displayName: options.value.brands[brand.value].label,
				displayOnPDF: options.value.brands[brand.value].label,
				val: options.value.brands[brand.value].unity
			};
			event.json.elevatortype = {
				displayName: options.value.types[type.value].label,
				displayOnPDF: options.value.types[type.value].label,
				val: options.value.types[type.value].value
			};
			event.json.locationname = location.value;
			fetch("https://node.test.holofair.io/save/"+projectName.value.trim().toLowerCase().replace(/\s+/g, '_'), {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(event.json)
			}).then(response => {
				if (!response.ok) throw new Error('Network response was not ok');
				return response.json();
			}).then(responseData => {
				console.log(responseData);
				if (responseData.url.startsWith("https://")) window.open(responseData.url,"_blank");
			}).catch(error => {
				console.error('There was a problem with the fetch operation:', error);
			});
		}
	}
}

function loadDesign() {
	fetch(options.value.sizes[size.value].link).then(response => {
		if (!response.ok) throw new Error('Network response was not ok');
		return response.json();
	}).then(data => {
		console.log(data);
		unityContext.send("SubjectsFactory","Load", JSON.stringify(data));
		showModal.value = false;
		unityContext.send("Main Camera","SetCaptureAllKeyboardInput","True");
	}).catch(error => {
		console.error('There was a problem with the fetch operation:', error);
	});
}

onMounted(() => {
	window.handleUnitySceneLoaded = function() {
		// Your implementation here
	};

	window.addEventListener("message", (event) => handleMessage(event.data), false);
	
	container.value = document.querySelector('.centered-container');

	container.value.addEventListener('mousedown', (e) => {
		isDragging.value = true;
		currentY.value = e.clientY;
		container.value.style.transition = 'none';
	});

	window.addEventListener('mousemove', (e) => {
		if (isDragging.value) {
			let deltaY = e.clientY - currentY.value;
			let transformValue = getComputedStyle(container.value).transform;

			if (transformValue === 'none') {
				transformValue = `translate(-50%, ${deltaY}px)`;
			} else {
				let currentTranslateY = parseFloat(transformValue.split(',')[5].trim());
				let proposedTranslation = currentTranslateY + deltaY;
				if (proposedTranslation < initialY.value) proposedTranslation = initialY.value;

				transformValue = `translate(-50%, ${proposedTranslation}px)`;
			}
			
			container.value.style.transform = transformValue;
			currentY.value = e.clientY;
			window.getSelection().removeAllRanges();
		}
	});

	window.addEventListener('mouseup', () => {
		if (isDragging.value) {
			isDragging.value = false;
			container.value.style.transition = 'transform 0.3s ease-out';
			
			let transformValue = getComputedStyle(container.value).transform;
			let currentTranslateY = 0;

			if (transformValue !== 'none') {
				let matrixValues = transformValue.split('(')[1].split(')')[0].split(',').map(parseFloat);
				currentTranslateY = matrixValues.length === 6 ? matrixValues[5] : matrixValues[matrixValues.length - 2];
			}

			if (currentTranslateY > 150) container.value.style.transform = 'translate(-50%, 65%)';
			else container.value.style.transform = 'translate(-50%, 0)';
		}
	});

	fetch("https://ipinfo.io/json?token=edd414c089c3a5").then(response => response.json()).then(data => {
		const country = data.country;
		const city = data.city;
		console.log(`${country}, ${city}`);
		location.value = {
			displayName: `${country}, ${city}`,
			displayOnPDF: `${country}, ${city}`,
			val: data.ip
		};
	}).catch(error => console.error(error));
})
</script>
